import Fuse from 'fuse.js';
import * as constants from './constants';

// fuse.js fuzzy search setup
const menuItems = constants.menu.reduce((p, c) => p.concat(c.items), []);
const options = {
  includeMatches: true,
  threshold: 0.2,
  keys: ['name', 'description'],
};
const fuse = new Fuse(menuItems, options);

/**
 * method to filter original menu into items containing search terms
 * @param { string } search
 * @returns filtered menu with items containing search terms
 */
const filterMenuUsingSearch = (search) => {
  // get search results from fuse.js
  const searchResults = fuse.search(search);

  // no results mean no filtering required
  if (search.length === 0) return constants.menu;

  // filter based on search
  const filteredMenu = [];
  constants.menu.forEach(({ category, items }) => {
    const newItems = [];
    items.forEach((item) => {
      for (const res of searchResults) {
        if (
          res.item.name === item.name &&
          res.item.description === item.description
        ) {
          let nameMatch = null;
          let descriptionMatch = null;
          res.matches.forEach((obj) => {
            if (obj.key === 'name') nameMatch = obj;
            else if (obj.key === 'description') descriptionMatch = obj;
          });
          newItems.push({ ...item, nameMatch, descriptionMatch });
          break;
        }
      }
    });
    items = newItems;

    // omit this category if it contains no items
    if (items.length > 0) filteredMenu.push({ category, items });
  });

  // give back filtered menu
  return filteredMenu;
};

/**
 * method to get the current year
 * @returns the current year
 */
const getCurrentYear = () => {
  return new Date().getFullYear();
};

/**
 * method to get date in MM/dd/yyyy
 * @returns the date string
 */
const getDateString = () => {
  return new Date()
    .toLocaleDateString('en-US')
    .split('/')
    .map((s) => s.padStart(2, '0'))
    .join('/');
};

/**
 * method to get Date obj representing certain time of specific day
 * @param { Date obj } day
 * @param { string of HH:MM:SS format } timeStr
 * @returns new Date obj representing certain time of specific day
 */
const getSpecificTimeFromDay = (day, timeStr) => {
  const [hours, minutes, seconds] = timeStr.split(':');
  const date = new Date(day.getTime());
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  return date;
};

/**
 * method to determine if current time is valid for lunch specials
 * @returns boolean
 */
const isValidTimeForLunchSpecial = () => {
  const current = new Date();
  const begin = getSpecificTimeFromDay(current, '10:30:00');
  const end = getSpecificTimeFromDay(current, '15:00:00');
  const day = current.getDay();

  // must be not Sunday and between 10:30am and 3:00pm
  return day > 0 && begin <= current && current <= end;
};

/**
 * method to add number of minutes to date object
 * @param { Date obj } date
 * @param { number } minutes
 * @returns new Date obj
 */
const addMinutesToDate = (date, minutes) => {
  return new Date(date.getTime() + minutes * 60000);
};

/**
 * method to check if user can order (if restaurant is open)
 * @returns boolean of if user can place an order
 */
const isRestaurantOpen = () => {
  const current = new Date();
  let { open, close } = constants.hours[current.getDay()];
  open = getSpecificTimeFromDay(current, open);
  close = getSpecificTimeFromDay(current, close);

  // stop taking orders 30 min before closing
  close = addMinutesToDate(close, -20);
  return !isThanksgiving() && open <= current && current <= close;
};

/**
 * method to check if today is thanksgiving
 * @returns boolean of if it is thanksgiving
 */
const isThanksgiving = () => {
  const today = new Date();
  const year = today.getFullYear();
  const first = new Date(year, 10, 1);
  const dayOfWeek = first.getDay();
  const dayOfThanksgiving = 22 + ((11 - dayOfWeek) % 7);
  return today.getMonth() === 10 && today.getDay() === dayOfThanksgiving;
};

/**
 * method to check if selected time is still valid
 * @returns boolean of if selected time is valid
 */
const isSelectedTimeValid = (time) => {
  if (time == null) return false;
  const [rest, timeOfDay] = time.split(' ');
  const [hrs, min] = rest.split(':');
  const offset = timeOfDay === 'PM' ? 12 : 0;
  const selected = new Date();
  selected.setHours(parseInt(hrs) + offset);
  selected.setMinutes(parseInt(min));
  const threshold = addMinutesToDate(new Date(), 5);
  return selected >= threshold;
};

/**
 * method to get all valid times for scheduling pick up
 * @returns list of time strings
 */
const getValidScheduleTimes = () => {
  const current = new Date();
  let { open, close } = constants.hours[current.getDay()];
  open = getSpecificTimeFromDay(current, open);
  close = getSpecificTimeFromDay(current, close);

  const convertToTimeStr = (date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const timeOfDay = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    if (hours === 0) hours = 12;
    return `${hours}:${minutes} ${timeOfDay}`;
  };

  let time = open;
  const validTimes = [];
  const earliest = addMinutesToDate(current, 10);
  while (time <= close) {
    if (time > earliest) validTimes.push(convertToTimeStr(time));
    time = addMinutesToDate(time, 5);
  }
  return validTimes;
};

/**
 * method to return kabob-case id string from category
 * @param { string } category
 * @returns id string from category
 */
const getIdFromCategory = (category) => {
  return category.replaceAll(' ', '-') + '-anchor';
};

/**
 * method to return category from kabob-case id string
 * @param { string } id
 * @returns category string from id
 */
const getCategoryFromId = (id) => {
  const words = id.split('-');
  return words.slice(0, words.length - 1).join(' ');
};

/**
 * method to stringify the current cart and put it in localStorage
 * @param { object } cart
 * @returns null
 */
const setCartInLocalStorage = (cart) => {
  localStorage.setItem('cart', JSON.stringify(cart));
};

/**
 * method to stringify the current cart and put it in localStorage
 * @returns the cart object from localStorage or an empty object
 */
const getCartInLocalStorage = () => {
  try {
    return validateCart(JSON.parse(localStorage.getItem('cart')));
  } catch (e) {
    return [];
  }
};

/**
 * method to stringify the current cart and put it in localStorage
 * @param { object } cart
 * @returns the cart object with valid items
 */
const validateCart = (cart) => {
  if (!Array.isArray(cart)) return [];
  const menu = constants.menu
    .slice(1)
    .reduce((prev, curr) => prev.concat(curr.items), []);

  // keep any items that haven't been tampered with
  const validCart = cart.filter((obj) =>
    menu.some(
      (item) =>
        item.name === obj.name &&
        item.price === obj.price &&
        item.spicy === obj.spicy &&
        item.description === obj.description &&
        Number.isInteger(obj.amount) &&
        (typeof obj.specialInstructions === 'string' ||
          obj.specialInstructions instanceof String)
    )
  );

  // check if given item in cart is a lunch special item
  const isLunchSpecial = (name) => {
    const part = name.split('. ')[0];
    const isDigit = /^\d+$/;
    return part[0] === 'L' && isDigit.test(part.slice(1));
  };

  // filter out any time-sensitive items if not valid
  return validCart.filter(
    (obj) => !isLunchSpecial(obj.name) || isValidTimeForLunchSpecial()
  );
};

export {
  filterMenuUsingSearch,
  getCategoryFromId,
  getCurrentYear,
  getDateString,
  getIdFromCategory,
  isValidTimeForLunchSpecial,
  isRestaurantOpen,
  isSelectedTimeValid,
  setCartInLocalStorage,
  getCartInLocalStorage,
  getValidScheduleTimes,
};
