import React from 'react';
import CartItem from './CartItem';
import { Empty } from 'antd';

const Cart = ({ cart, updateItemCount }) => {
  return (
    <div className="checkout-item-display">
      <h2 className="checkout-item-display-title">Your Items</h2>
      {cart.length > 0 ? (
        cart.map((item, index) => (
          <CartItem
            key={index}
            index={index}
            item={item}
            updateItemCount={updateItemCount}
          />
        ))
      ) : (
        <Empty className="checkout-empty-icon" description="Cart is empty!" />
      )}
    </div>
  );
};

export default Cart;
