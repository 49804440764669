import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Select, Input } from 'antd';
import throttle from 'lodash/throttle';
import {
  SearchOutlined,
  ShoppingFilled,
  CloseCircleFilled,
  MenuOutlined,
} from '@ant-design/icons';
import * as constants from './../../resources/constants';
import * as utils from './../../resources/utils';

// variables for ease of access
const throttleTime = 200;
const minVisiblePixels = 100;
const categoryNodeType = 'H2';
const mobileMatchMedia = '(max-width: 768px)';
const searchInputStyle = {
  focusScale: 'scaleX(0)',
  focusTranslate: 'translateX(-245px)',
  blurScale: 'scaleX(1)',
  blurTranslate: 'translateX(0)',
};

const MenuBar = ({ menu, search, setSearch, cart }) => {
  // ------------------------------------------------------------------
  // ----------------------------- state ------------------------------
  // ------------------------------------------------------------------

  const numItems = cart.reduce((currAmount, obj) => currAmount + obj.amount, 0);
  const history = useHistory();
  const inputRef = useRef(null);
  const collapseRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState('Popular Items');
  const [isMobile, setIsMobile] = useState(
    window.matchMedia(mobileMatchMedia).matches
  );

  // ------------------------------------------------------------------
  // --------------------------- life cycle ---------------------------
  // ------------------------------------------------------------------

  // reset search if in mobile mode
  useEffect(() => {
    if (isMobile && search.length > 0) setSearch('');
  }, [isMobile, search, setSearch]);

  // set up animations for menu bar
  useEffect(() => {
    // grab dom elements
    const inputContainer = document.querySelector('.menu-search-container');
    const input = inputRef.current?.input;
    const collapse = collapseRef.current;

    // define handlers for focusing and blurring
    const focusHandler = () => {
      if (collapse) {
        collapse.style.transform = searchInputStyle.focusScale;
        inputContainer.style.transform = searchInputStyle.focusTranslate;
      }
    };
    const blurHandler = () => {
      if (collapse) {
        collapse.style.transform = searchInputStyle.blurScale;
        inputContainer.style.transform = searchInputStyle.blurTranslate;
      }
    };

    // set up event listeners
    if (input) {
      input.addEventListener('focus', focusHandler);
      input.addEventListener('blur', blurHandler);
    }

    // remove event listeners
    return () => {
      if (input) {
        input.removeEventListener('focus', focusHandler);
        input.removeEventListener('blur', blurHandler);
      }
    };
  }, [isMobile]);

  // conditional rendering for different size screens
  useEffect(() => {
    const resizeHandler = throttle(() => {
      const match = window.matchMedia(mobileMatchMedia).matches;
      if (isMobile !== match) setIsMobile(match);
    }, throttleTime);
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [isMobile]);

  // change active category based on position on page
  useEffect(() => {
    const scrollHandler = throttle(() => {
      let found = null;
      for (const element of document.getElementsByClassName('menu-section')) {
        const { top, bottom } = element.getBoundingClientRect();
        const size = Math.min(bottom, window.innerHeight) - Math.max(top, 0);
        if (size > minVisiblePixels) {
          const categoryNode = element.firstElementChild;
          if (categoryNode.nodeName === categoryNodeType) found = categoryNode;
          break;
        }
      }
      if (found) {
        const category = utils.getCategoryFromId(found.id);
        if (activeCategory !== category) setActiveCategory(category);
      }
    }, throttleTime);
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [activeCategory]);

  // ------------------------------------------------------------------
  // ---------------------------- handlers ----------------------------
  // ------------------------------------------------------------------

  const goToURL = (url) => {
    const oldURL = history.location.pathname;
    if (oldURL !== url) {
      history.push(url);
      window.scrollTo(0, 0);
    }
  };

  const scrollToAnchor = (category) => {
    const anchor = utils.getIdFromCategory(category);
    const anchorNode = document.getElementById(anchor);
    const { top } = anchorNode.parentNode.getBoundingClientRect();
    window.scrollTo(0, window.scrollY + top - 100);
  };

  // ------------------------------------------------------------------
  // ----------------------------- render -----------------------------
  // ------------------------------------------------------------------

  return (
    <div className="menu-sticky-bar">
      <div className="menu-sticky-bar-items">
        <div className="menu-sticky-bar-search-and-select">
          <div ref={collapseRef} className="menu-collapsible-container">
            {isMobile && (
              <MenuOutlined
                onClick={() => setDropdownOpen(!dropdownOpen)}
                style={{
                  paddingLeft: '11px',
                  paddingRight: '5px',
                }}
              />
            )}
            <Select
              className="menu-category-select"
              value={activeCategory}
              dropdownStyle={{ display: 'none' }}
              showArrow={!isMobile}
              bordered={false}
              open={dropdownOpen}
              onClick={() => setDropdownOpen(!dropdownOpen)}
              onBlur={() => setDropdownOpen(false)}
              onFocus={() => setDropdownOpen(true)}
            />
            {dropdownOpen && (
              <div className="dropdown-menu">
                {menu.map(({ category }, index) => (
                  <div
                    key={index}
                    className="dropdown-option"
                    onMouseDown={() => {
                      scrollToAnchor(category);
                      setDropdownOpen(false);
                    }}
                  >
                    {category === activeCategory && (
                      <div className="active-indicator"></div>
                    )}
                    <span
                      id={`dropdown-text-id-${index}`}
                      className="dropdown-option-text"
                    >
                      {category}
                    </span>
                  </div>
                ))}
              </div>
            )}
            {!isMobile && <div className="vertical-divider" />}
          </div>
          {!isMobile && (
            <Input
              ref={inputRef}
              className="menu-search-container"
              bordered={false}
              prefix={<SearchOutlined />}
              placeholder="Search items"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              suffix={
                search && (
                  <CloseCircleFilled
                    onClick={() => setSearch('')}
                    onMouseDown={(e) => e.preventDefault()}
                  />
                )
              }
            />
          )}
        </div>
        <div className="cart-button-container">
          <div
            className="cart-button"
            onClick={() => goToURL(constants.urls.checkout)}
          >
            <ShoppingFilled style={{ marginBottom: '2px' }} />
            {isMobile ? (
              numItems > 0 && (
                <div className="cart-button-badge">
                  {numItems < 100 ? numItems : '99+'}
                </div>
              )
            ) : (
              <>
                <div className="vertical-divider" />
                <span>{`${numItems} Item${numItems === 1 ? '' : 's'}`}</span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuBar;
