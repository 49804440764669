import React from 'react';
import Navbar from './../components/Shared/Navbar';
import Menu from './../components/MenuPage/Menu';
import bannerAVIF from './../styles/assets/images/banner.avif';
import bannerWEBP from './../styles/assets/images/banner.webp';
import bannerJPEG from './../styles/assets/images/banner.jpg';

const MenuPage = ({ cart, addToCart }) => (
  <>
    <div className="navbar-banner-container">
      <Navbar />
      <div className="banner">
        <picture>
          <source type="image/avif" srcSet={bannerAVIF} />
          <source type="image/webp" srcSet={bannerWEBP} />
          <img
            className="banner-image"
            alt="banner"
            src={bannerJPEG}
            width="2200"
            height="846"
          />
        </picture>
      </div>
    </div>
    <Menu cart={cart} addToCart={addToCart} />
  </>
);

export default MenuPage;
