import React, { useState } from 'react';
import { message } from 'antd';
import MenuModal from './MenuModal';
import spicy from './../../styles/assets/images/spicy.png';
import * as utils from './../../resources/utils';

message.config({
  duration: 2,
  maxCount: 1,
});

const MenuItem = ({ item, addToCart, category }) => {
  // ------------------------------------------------------------------
  // ----------------------------- state ------------------------------
  // ------------------------------------------------------------------

  const [open, setOpen] = useState(false);

  // ------------------------------------------------------------------
  // ---------------------------- handlers ----------------------------
  // ------------------------------------------------------------------

  // highlight search terms in text
  const highlightText = (text, match) => {
    // shorten text
    const slice = text.slice(0, 120);
    if (slice !== text) text = slice + '...';

    // if there are no search terms, return text as is
    if (!match) return text;

    // figure out which letters are highlighted
    let highlighted = Array(match.value.length).fill(false);
    match.indices.forEach(([start, end]) => {
      for (let i = start; i <= end; i++) {
        highlighted[i] = true;
      }
    });

    // shorten text
    highlighted = highlighted.slice(0, 120);

    // fill in segments
    const segments = [];
    let prev = 0;
    let wrapped = highlighted[0];
    for (let i = 1; i < highlighted.length; i++) {
      if (highlighted[i] !== wrapped) {
        segments.push([wrapped, match.value.slice(prev, i)]);
        prev = i;
        wrapped = highlighted[i];
      }
    }
    segments.push([wrapped, match.value.slice(prev)]);

    // return wrapped and unwrapped segments
    return segments.map(([isWrapped, piece], index) => (
      <React.Fragment key={index}>
        {isWrapped ? (
          <span className="highlighted-text">{piece}</span>
        ) : (
          <>{piece}</>
        )}
      </React.Fragment>
    ));
  };

  // handler for clicking a card
  const clickHandler = () => {
    const isLunchSpecial = category === 'Lunch Specials';
    const isValidTime = utils.isValidTimeForLunchSpecial();
    const disabled = isLunchSpecial && !isValidTime;
    if (disabled) {
      message.error('Cannot order outside of lunch hours!');
    } else {
      setOpen(true);
    }
  };

  // ------------------------------------------------------------------
  // ----------------------------- render -----------------------------
  // ------------------------------------------------------------------

  return (
    <>
      <div className="menu-card" onClick={clickHandler}>
        <div className="card-top-container">
          <div className="card-name-spicy-container">
            <h5>{highlightText(item.name, item.nameMatch)}</h5>
            {item.spicy && (
              <img alt="spicy" src={spicy} width="15" height="15" />
            )}
          </div>
          {item.description && (
            <div className="card-description">
              {highlightText(item.description, item.descriptionMatch)}
            </div>
          )}
        </div>
        <span className="card-price">{'$' + item.price.toFixed(2)}</span>
      </div>
      <MenuModal
        item={item}
        open={open}
        setOpen={setOpen}
        addToCart={addToCart}
        category={category}
      />
    </>
  );
};

export default MenuItem;
