import React from 'react';
import MenuItem from './MenuItem';
import * as utils from './../../resources/utils';

const Category = ({ category, items, addToCart }) => {
  const isLunchSpecial = category === 'Lunch Specials';
  return (
    <div className="menu-section">
      <h2 id={utils.getIdFromCategory(category)} className="category-name">
        {category}
        {isLunchSpecial && (
          <div className="category-extra-info">
            Served Mon. thru Sat. from 10:30am - 3:00pm
          </div>
        )}
      </h2>
      <div className="cards-container">
        {items.map((item, index) => (
          <MenuItem
            key={index}
            item={item}
            addToCart={addToCart}
            category={category}
          />
        ))}
      </div>
    </div>
  );
};

export default Category;
