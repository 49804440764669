import React, { useState } from 'react';
import Navbar from './../components/Shared/Navbar';
import CheckoutSteps from '../components/CheckoutPage/CheckoutSteps';
import Cart from '../components/CheckoutPage/Cart';
import PaymentDetails from '../components/CheckoutPage/PaymentDetails';
import ContactInfo from '../components/CheckoutPage/ContactInfo';
import Confirmation from '../components/CheckoutPage/Confirmation';
import { Form, Spin } from 'antd';

const CheckoutPage = ({ cart, updateItemCount, updateCart }) => {
  // ------------------------------------------------------------------
  // ----------------------------- state ------------------------------
  // ------------------------------------------------------------------

  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // ------------------------------------------------------------------
  // ----------------------------- logic ------------------------------
  // ------------------------------------------------------------------

  const getPageUsingStep = (step) => {
    // payment details component that is shared between screens
    const paymentDetails = (
      <PaymentDetails
        cart={cart}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        form={form}
      />
    );

    // dynamic screens based on current step in the checkout process
    switch (step) {
      case 0:
        return (
          <>
            <Cart cart={cart} updateItemCount={updateItemCount} />
            {paymentDetails}
          </>
        );
      case 1:
        return (
          <>
            <ContactInfo
              cart={cart}
              form={form}
              setLoading={setLoading}
              setCurrentStep={setCurrentStep}
            />
            {paymentDetails}
          </>
        );
      case 2:
        return <Confirmation form={form} updateCart={updateCart} />;
      default:
        break;
    }
  };

  // ------------------------------------------------------------------
  // ----------------------------- render -----------------------------
  // ------------------------------------------------------------------

  return (
    <Spin
      spinning={loading}
      size="large"
      style={{
        position: 'fixed',
        height: '100%',
        width: '100%',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
    >
      <Navbar />
      <div className="checkout-container">
        <CheckoutSteps step={currentStep} setCurrentStep={setCurrentStep} />
        <div className="checkout-items-and-confirmation-container">
          {getPageUsingStep(currentStep)}
        </div>
      </div>
    </Spin>
  );
};

export default CheckoutPage;
