import React from 'react';
import { message } from 'antd';
import * as constants from '../../resources/constants';
import * as utils from '../../resources/utils';

message.config({
  duration: 2,
  maxCount: 1,
});

const PaymentDetails = ({ cart, currentStep, setCurrentStep, form }) => {
  // ------------------------------------------------------------------
  // ----------------------------- logic ------------------------------
  // ------------------------------------------------------------------

  const subtotal = cart.reduce((ans, obj) => ans + obj.amount * obj.price, 0);
  const tax = subtotal * constants.taxRate;
  const total = subtotal + tax;

  // ------------------------------------------------------------------
  // ---------------------------- handlers ----------------------------
  // ------------------------------------------------------------------

  const checkoutHandler = () => {
    switch (currentStep) {
      case 0:
        handleCartReview();
        break;
      case 1:
        handleContactValidation();
        break;
      default:
        break;
    }
  };

  const handleCartReview = () => {
    if (cart.length === 0) {
      message.error('Cannot checkout with empty cart!');
    } else if (!utils.isRestaurantOpen()) {
      message.error('Restaurant is not taking online orders right now!');
    } else {
      setCurrentStep(1);
      window.scrollTo(0, 0);
    }
  };

  const handleContactValidation = () => {
    form.submit();
  };

  // ------------------------------------------------------------------
  // ----------------------------- render -----------------------------
  // ------------------------------------------------------------------

  return (
    <div className="checkout-confirm-display">
      <h2 className="checkout-confirm-display-title">Payment Details</h2>
      <div className="checkout-price-info-container">
        <div className="checkout-price-subtotal-info">
          <div className="checkout-space-between">
            Subtotal:
            <b>${subtotal.toFixed(2)}</b>
          </div>
          <div className="checkout-space-between">
            Tax:
            <b>${tax.toFixed(2)}</b>
          </div>
          <div className="checkout-space-between">
            <b>You pay:</b>
            <b>${total.toFixed(2)}</b>
          </div>
        </div>
        <div className="checkout-confirm-button" onClick={checkoutHandler}>
          {currentStep === 0 ? 'Checkout' : 'Submit'}
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
