export const menu = [
  {
    category: 'Popular Items',
    items: [
      {
        name: 'Crabmeat Rangoon',
        price: 8.95,
        description: 'Eight pieces.',
      },
      {
        name: 'Fried Dumplings',
        price: 9.5,
        description: 'Eight pieces.',
      },
      {
        name: 'Boneless Spare Ribs',
        price: 16.5,
      },
      {
        name: 'Singapore Mei Fun',
        price: 14.5,
        spicy: true,
      },
      {
        name: 'House Special Lo Mein',
        price: 13.5,
      },
      {
        name: "General Tso's Chicken",
        price: 14.95,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Sesame Chicken',
        price: 14.95,
        description: 'Served with white rice.',
      },
      {
        name: 'Bourbon Chicken',
        price: 16.95,
        description: 'Served with white rice.',
      },
      {
        name: 'Sweet & Sour Chicken',
        price: 13.5,
        description: 'Served with white rice.',
      },
    ],
  },

  // --------------------------------------------------------------------------------------------

  {
    category: 'Lunch Specials',
    items: [
      {
        name: "L1. General Tso's Chicken",
        price: 9.95,
        spicy: true,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L2. Diced Chicken with Cashew Nuts',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L3. Chicken with Broccoli',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L4. Moo Goo Gai Pan',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L5. Chicken Chow Mein',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L5. Shrimp Chow Mein',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L5. Roast Pork Chow Mein',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L5. Beef Chow Mein',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L5. Vegetable Chow Mein',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L6. Sweet & Sour Chicken',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L6. Sweet & Sour Pork',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L7. Sesame Chicken',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L8. Chicken with Garlic Sauce',
        price: 9.95,
        spicy: true,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L9. Pepper Steak with Onion',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L10. Beef with Broccoli',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L11. Mixed Chinese Vegetables',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L12. Roast Pork Lo Mein',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L12. Beef Lo Mein',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L12. Shrimp Lo Mein',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L12. Chicken Lo Mein',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L12. Vegetable Lo Mein',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L13. Roast Pork with Mixed Vegetables',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L13. Chicken with Mixed Vegetables',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L13. Beef with Mixed Vegetables',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L14. Hot & Spicy Shredded Pork',
        price: 9.95,
        spicy: true,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L15. Shredded Pork with String Beans',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L16. Roast Pork Egg Foo Young',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L17. Boneless Spare Ribs',
        price: 10.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L18. Shrimp with Lobster Sauce',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L19. Shrimp with Mixed Vegetables',
        price: 9.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L20. Shrimp with Garlic Sauce',
        price: 9.95,
        spicy: true,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L21. Broccoli with Garlic Sauce',
        price: 9.95,
        spicy: true,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L22. Hunan Chicken',
        price: 9.95,
        spicy: true,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L22. Hunan Pork',
        price: 9.95,
        spicy: true,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L23. Kung Po Chicken',
        price: 9.95,
        spicy: true,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L24. Fried Chicken Wings (3)',
        price: 10.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L25. Spare Rib Tips (with Bone)',
        price: 10.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L26. Mongolian Chicken',
        price: 9.95,
        spicy: true,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
      {
        name: 'L27. Bourbon Chicken',
        price: 10.95,
        description: 'Served with roast pork fried rice and a 12 oz can soda.',
      },
    ],
  },

  // --------------------------------------------------------------------------------------------

  {
    category: 'Appetizers',
    items: [
      {
        name: 'Egg Roll',
        price: 2.0,
        description: 'One piece.',
      },
      {
        name: 'Shrimp Egg Roll',
        price: 2.5,
        description: 'One piece.',
      },
      {
        name: 'Spring Egg Roll',
        price: 2.0,
        description: 'One piece.',
      },
      {
        name: 'Fried Wontons',
        price: 8.5,
        description: 'Ten pieces.',
      },
      {
        name: 'Fried Dumplings',
        price: 9.5,
        description: 'Eight pieces.',
      },
      {
        name: 'Steamed Dumplings',
        price: 9.5,
        description: 'Eight pieces.',
      },
      {
        name: 'Shrimp Toast',
        price: 8.5,
        description: 'Six pieces.',
      },
      {
        name: 'Bar-B-Q Spare Ribs (S)',
        price: 10.95,
      },
      {
        name: 'Bar-B-Q Spare Ribs (L)',
        price: 18.0,
      },
      {
        name: 'Beef Teriyaki',
        price: 10.95,
        description: 'Four pieces.',
      },
      {
        name: 'Chicken Teriyaki',
        price: 10.5,
        description: 'Four pieces.',
      },
      {
        name: 'Chicken Wings',
        price: 8.95,
        description: 'Four pieces.',
      },
      {
        name: 'Chicken Wings with Pork Fried Rice',
        price: 12.0,
        description: 'Four pieces.',
      },
      {
        name: 'Boneless Spare Ribs',
        price: 16.5,
      },
      {
        name: 'Spare Rib Tips (with Bone)',
        price: 16.5,
      },
      {
        name: 'Cold Noodles with Sesame Sauce',
        price: 9.9,
        spicy: true,
      },
      {
        name: 'Crabmeat Rangoon',
        price: 8.95,
        description: 'Eight pieces.',
      },
      {
        name: 'Pu Pu Platter',
        price: 19.5,
        description:
          'Serves two. Contains Chicken Wings, BBQ, Spring Roll, Beef Teriyaki, Shrimp Toast, and Fried Wonton.',
      },
    ],
  },

  // --------------------------------------------------------------------------------------------

  {
    category: 'Soups',
    items: [
      {
        name: 'Wonton Soup (Pt)',
        price: 3.95,
        description: 'Served with crispy noodles.',
      },
      {
        name: 'Wonton Soup (Qt)',
        price: 6.5,
        description: 'Served with crispy noodles.',
      },
      {
        name: 'Egg Drop Soup (Pt)',
        price: 3.95,
        description: 'Served with crispy noodles.',
      },
      {
        name: 'Egg Drop Soup (Qt)',
        price: 6.5,
        description: 'Served with crispy noodles.',
      },
      {
        name: 'Wonton Egg Drop Soup (Pt)',
        price: 4.25,
        description: 'Served with crispy noodles.',
      },
      {
        name: 'Wonton Egg Drop Soup (Qt)',
        price: 6.95,
        description: 'Served with crispy noodles.',
      },
      {
        name: 'Chicken Rice Soup (Pt)',
        price: 3.95,
        description: 'Served with crispy noodles.',
      },
      {
        name: 'Chicken Rice Soup (Qt)',
        price: 6.5,
        description: 'Served with crispy noodles.',
      },
      {
        name: 'Chicken Noodle Soup (Pt)',
        price: 3.95,
        description: 'Served with crispy noodles.',
      },
      {
        name: 'Chicken Noodle Soup (Qt)',
        price: 6.5,
        description: 'Served with crispy noodles.',
      },
      {
        name: 'Hot & Sour Soup (Pt)',
        price: 3.95,
        spicy: true,
        description: 'Served with crispy noodles.',
      },
      {
        name: 'Hot & Sour Soup (Qt)',
        price: 6.5,
        spicy: true,
        description: 'Served with crispy noodles.',
      },
      {
        name: 'House Special Soup',
        price: 9.95,
        description: 'Served with crispy noodles.',
      },
    ],
  },

  // --------------------------------------------------------------------------------------------

  {
    category: 'Chow Mein & Chop Suey',
    items: [
      {
        name: 'Roast Pork Chow Mein',
        price: 12.5,
        description: 'Served with crispy noodles and white rice.',
      },
      {
        name: 'Chicken Chow Mein',
        price: 12.5,
        description: 'Served with crispy noodles and white rice.',
      },
      {
        name: 'Shrimp Chow Mein',
        price: 13.5,
        description: 'Served with crispy noodles and white rice.',
      },
      {
        name: 'Vegetable Chow Mein',
        price: 12.5,
        description: 'Served with crispy noodles and white rice.',
      },
      {
        name: 'House Special Chow Mein',
        price: 14.5,
        description: 'Served with crispy noodles and white rice.',
      },
      {
        name: 'Roast Pork Chop Suey',
        price: 12.5,
        description: 'Served with crispy noodles and white rice.',
      },
      {
        name: 'Chicken Chop Suey',
        price: 12.5,
        description: 'Served with crispy noodles and white rice.',
      },
      {
        name: 'Shrimp Chop Suey',
        price: 13.5,
        description: 'Served with crispy noodles and white rice.',
      },
      {
        name: 'Vegetable Chop Suey',
        price: 12.5,
        description: 'Served with crispy noodles and white rice.',
      },
      {
        name: 'House Special Chop Suey',
        price: 14.5,
        description: 'Served with crispy noodles and white rice.',
      },
    ],
  },

  // --------------------------------------------------------------------------------------------

  {
    category: 'Lo Mein',
    items: [
      {
        name: 'Roast Pork Lo Mein',
        price: 11.5,
      },
      {
        name: 'Chicken Lo Mein',
        price: 11.5,
      },
      {
        name: 'Beef Lo Mein',
        price: 12.5,
      },
      {
        name: 'Shrimp Lo Mein',
        price: 12.5,
      },
      {
        name: 'Vegetable Lo Mein',
        price: 11.5,
      },
      {
        name: 'House Special Lo Mein',
        price: 13.5,
      },
    ],
  },

  // --------------------------------------------------------------------------------------------

  {
    category: 'Egg Foo Young',
    items: [
      {
        name: 'Roast Pork Egg Foo Young',
        price: 12.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Chicken Egg Foo Young',
        price: 12.5,
        description: 'Served with white rice.',
      },
      {
        name: 'House Special Egg Foo Young',
        price: 14.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Shrimp Egg Foo Young',
        price: 13.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Vegetable Egg Foo Young',
        price: 12.5,
        description: 'Served with white rice.',
      },
    ],
  },

  // --------------------------------------------------------------------------------------------

  {
    category: 'Mei Fun',
    items: [
      {
        name: 'Roast Pork Mei Fun',
        price: 12.5,
      },
      {
        name: 'Chicken Mei Fun',
        price: 12.5,
      },
      {
        name: 'Shrimp Mei Fun',
        price: 13.5,
      },
      {
        name: 'House Special Mei Fun',
        price: 14.5,
      },
      {
        name: 'Singapore Mei Fun',
        price: 14.5,
        spicy: true,
      },
    ],
  },

  // --------------------------------------------------------------------------------------------

  {
    category: 'Vegetables',
    items: [
      {
        name: 'Vegetable Delight',
        price: 11.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Sauteed Broccoli',
        price: 11.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Sauteed String Beans',
        price: 11.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Broccoli with Garlic Sauce',
        price: 11.5,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Bean Curd Szechuan Style',
        price: 13.0,
        spicy: true,
        description: 'Served with white rice.',
      },
    ],
  },

  // --------------------------------------------------------------------------------------------

  {
    category: 'Fried Rice',
    items: [
      {
        name: 'Roast Pork Fried Rice',
        price: 10.5,
      },
      {
        name: 'Chicken Fried Rice',
        price: 10.5,
      },
      {
        name: 'Vegetable Fried Rice',
        price: 10.5,
      },
      {
        name: 'Beef Fried Rice',
        price: 11.5,
      },
      {
        name: 'Shrimp Fried Rice',
        price: 11.5,
      },
      {
        name: 'House Special Fried Rice',
        price: 12.5,
      },
    ],
  },

  // --------------------------------------------------------------------------------------------

  {
    category: 'Pork',
    items: [
      {
        name: 'Roast Pork with Mixed Vegetables',
        price: 13.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Roast Pork with Broccoli',
        price: 13.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Sweet & Sour Pork',
        price: 13.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Roast Pork Szechuan Style',
        price: 13.5,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Roast Pork with Snow Peas',
        price: 13.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Shredded Pork with String Beans',
        price: 13.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Roast Pork with Garlic Sauce',
        price: 13.5,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Hot & Spicy Shredded Pork',
        price: 13.5,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Moo Shu Pork',
        price: 13.5,
        description: 'Served with pancakes. Does not come with rice.',
      },
      {
        name: 'Mongolian Shredded Pork',
        price: 13.5,
        spicy: true,
        description: 'Served with noodles.',
      },
    ],
  },

  // --------------------------------------------------------------------------------------------

  {
    category: 'Chicken',
    items: [
      {
        name: 'Chicken with Broccoli',
        price: 13.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Moo Goo Gai Pan (Sliced Chicken)',
        price: 13.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Sweet & Sour Chicken',
        price: 13.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Chicken with Cashew Nuts',
        price: 13.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Curry Chicken with Onion',
        price: 13.5,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Chicken with Black Bean Sauce',
        price: 13.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Honey Chicken',
        price: 13.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Chicken with Snow Peas',
        price: 13.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Boneless Chicken (Fried)',
        price: 13.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Lemon Chicken',
        price: 13.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Moo Shu Chicken',
        price: 13.5,
        description: 'Served with pancakes. Does not come with rice.',
      },
      {
        name: 'Chicken with Garlic Sauce',
        price: 13.5,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Chicken Szechaun Style',
        price: 13.5,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Kung Po Chicken',
        price: 13.5,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Hunan Chicken',
        price: 13.5,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Chicken with Mixed Vegetables',
        price: 13.5,
        description: 'Served with white rice.',
      },
      {
        name: 'Mongolian Chicken',
        price: 13.5,
        spicy: true,
        description: 'Served with noodles.',
      },
    ],
  },

  // --------------------------------------------------------------------------------------------

  {
    category: 'Beef',
    items: [
      {
        name: 'Pepper Steak with Onion',
        price: 14.95,
        description: 'Served with white rice.',
      },
      {
        name: 'Beef with Broccoli',
        price: 14.95,
        description: 'Served with white rice.',
      },
      {
        name: 'Beef with Mixed Vegetables',
        price: 14.95,
        description: 'Served with white rice.',
      },
      {
        name: 'Beef with Snow Peas',
        price: 14.95,
        description: 'Served with white rice.',
      },
      {
        name: 'Shredded Beef with String Beans',
        price: 14.95,
        description: 'Served with white rice.',
      },
      {
        name: 'Beef with Garlic Sauce',
        price: 14.95,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Beef Szechuan Style',
        price: 14.95,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Hot & Spicy Shredded Beef',
        price: 14.95,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Hunan Beef',
        price: 14.95,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Mongolian Beef',
        price: 14.95,
        spicy: true,
        description: 'Served with noodles.',
      },
    ],
  },

  // --------------------------------------------------------------------------------------------

  {
    category: 'Seafood',
    items: [
      {
        name: 'Shrimp with Broccoli',
        price: 14.95,
        description: 'Served with white rice.',
      },
      {
        name: 'Shrimp with Mixed Vegetables',
        price: 14.95,
        description: 'Served with white rice.',
      },
      {
        name: 'Shrimp with Lobster Sauce',
        price: 14.95,
        description: 'Served with white rice.',
      },
      {
        name: 'Shrimp with Snow Peas',
        price: 14.95,
        description: 'Served with white rice.',
      },
      {
        name: 'Curry Shrimp with Onion',
        price: 14.95,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Shrimp with Black Bean Sauce',
        price: 14.95,
        description: 'Served with white rice.',
      },
      {
        name: 'Shrimp with Cashew Nuts',
        price: 14.95,
        description: 'Served with white rice.',
      },
      {
        name: 'Shrimp Szechuan Style',
        price: 14.95,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Sweet & Sour Shrimp',
        price: 14.95,
        description: 'Served with white rice.',
      },
      {
        name: 'Moo Shu Shrimp',
        price: 14.95,
        description: 'Served with pancakes.',
      },
      {
        name: 'Shrimp with Garlic Sauce',
        price: 14.95,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Shrimp with Chili Sauce',
        price: 14.95,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Kung Po Shrimp',
        price: 14.95,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Scallops with Garlic Sauce',
        price: 17.95,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Hunan Shrimp',
        price: 14.95,
        spicy: true,
        description: 'Served with white rice.',
      },
    ],
  },

  // --------------------------------------------------------------------------------------------

  {
    category: 'Combination Platters',
    items: [
      {
        name: 'C1. Chicken Chow Mein',
        price: 12.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C2. Chicken with Cashew Nuts',
        price: 12.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C3. Shrimp Chow Mein',
        price: 12.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C4. Moo Goo Gai Pan (Chicken)',
        price: 12.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C5. Pepper Steak with Onion',
        price: 12.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C6. Roast Pork Egg Foo Young',
        price: 12.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C7. Chicken with Broccoli',
        price: 12.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C8. Sweet & Sour Chicken',
        price: 12.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C9. Roast Pork Lo Mein',
        price: 12.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C10. Beef with Broccoli',
        price: 12.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C11. Chicken with Garlic Sauce',
        price: 12.95,
        spicy: true,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C12. Chicken Lo Mein',
        price: 12.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C13. Hot & Spicy Shredded Pork',
        price: 12.95,
        spicy: true,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C14. Shrimp with Lobster Sauce',
        price: 12.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C15. Boneless Spare Ribs',
        price: 13.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: "C16. General Tso's Chicken",
        price: 12.95,
        spicy: true,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C17. Sesame Chicken',
        price: 12.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C18. Chicken with Mixed Vegetables',
        price: 12.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C19. Sweet & Sour Pork',
        price: 12.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C20. Shrimp with Broccoli',
        price: 12.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C21. Shrimp with Mixed Vegetables',
        price: 12.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C22. Shrimp Lo Mein',
        price: 12.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
      {
        name: 'C23. Bourbon Chicken',
        price: 13.95,
        description: 'Served with roast pork fried rice and an egg roll.',
      },
    ],
  },

  // --------------------------------------------------------------------------------------------

  {
    category: 'House Specials',
    items: [
      {
        name: "General Tso's Chicken",
        price: 14.95,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Orange Chicken',
        price: 14.95,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Sesame Chicken',
        price: 14.95,
        description: 'Served with white rice.',
      },
      {
        name: 'Triple Delight',
        price: 16.95,
        description:
          'Chicken, beef, and shrimp with mixed vegetables in brown sauce. Served with white rice.',
      },
      {
        name: 'Scallop & Beef',
        price: 17.95,
        description: 'Mixed vegetables in brown sauce. Served with white rice.',
      },
      {
        name: 'Happy Family',
        price: 17.95,
        description:
          'Roast pork, scallop, chicken, shrimp, artificial crabmeat, and beef with mixed vegetables in brown sauce. Served with white rice.',
      },
      {
        name: 'Seafood Delight',
        price: 17.95,
        description:
          'Lobster meat, scallops, artificial crabmeat, and shrimp sauteed with mixed vegetables in white sauce. Served with white rice.',
      },
      {
        name: 'Five "O"',
        price: 17.95,
        description:
          'Shrimps, scallops, chicken, beef, and roast pork with mixed vegetables in garlic sauce. Served with white rice.',
        spicy: true,
      },
      {
        name: 'Orange Beef',
        price: 19.95,
        description:
          'Tenderloin beef steak with orange peel in a spicy sauce. Served with white rice.',
        spicy: true,
      },
      {
        name: 'Subgum Wonton',
        price: 19.95,
        description:
          'Jumbo shrimp, chicken, roast pork, lobster meat, and artificial crabmeat with mixed vegetables in brown sauce. Served with crispy wonton and white rice.',
      },
      {
        name: 'Dragon & Phoenix',
        price: 19.95,
        description:
          "Half Shrimp with Chili Sauce and half General Tso's Chicken. Served with white rice.",
      },
      {
        name: 'Four Seasons',
        price: 17.95,
        description:
          'Chicken, beef, shrimp, and roast pork with mixed vegetables in brown sauce. Served with white rice.',
      },
      {
        name: 'Jade Shrimp & Scallop',
        price: 17.95,
        description:
          'Jumbo shrimp and scallop with broccoli, green pepper, and snow peas with brown and spicy sauce. Served with white rice.',
      },
      {
        name: "General Tso's Shrimp",
        price: 17.95,
        spicy: true,
        description: 'Served with white rice.',
      },
      {
        name: 'Sesame Shrimp',
        price: 17.95,
        description: 'Served with white rice.',
      },
      {
        name: 'Szechuan Triple Delight',
        price: 16.95,
        spicy: true,
        description:
          'Jumbo shrimp, chicken, and roast pork with mixed vegetables in Szechuan sauce. Served with white rice.',
      },
      {
        name: 'Bourbon Chicken',
        price: 16.95,
        description: 'Served with white rice.',
      },
    ],
  },

  // --------------------------------------------------------------------------------------------

  {
    category: 'Side Orders',
    items: [
      {
        name: 'Steamed Rice (Lg)',
        price: 3.5,
      },
      {
        name: 'Crispy Noodles (Bag)',
        price: 0.75,
      },
      {
        name: 'Canned Soda',
        price: 1.5,
      },
    ],
  },
];

export const urls = {
  home: '/',
  info: '/',
  menu: '/menu',
  checkout: '/checkout',
};

export const taxRate = 0.07;

export const hours = [
  {
    day: 'Sunday',
    open: '11:00:00',
    close: '21:30:00',
  },
  {
    day: 'Monday',
    open: '10:30:00',
    close: '22:00:00',
  },
  {
    day: 'Tuesday',
    open: '10:30:00',
    close: '22:00:00',
  },
  {
    day: 'Wednesday',
    open: '10:30:00',
    close: '22:00:00',
  },
  {
    day: 'Thursday',
    open: '10:30:00',
    close: '22:00:00',
  },
  {
    day: 'Friday',
    open: '10:30:00',
    close: '22:30:00',
  },
  {
    day: 'Saturday',
    open: '10:30:00',
    close: '22:30:00',
  },
];

export const address = '1475 Palm Coast Pkwy NW, Palm Coast, FL, 32137';
