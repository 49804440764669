import React, { useState } from 'react';
import MenuBar from './MenuBar';
import Category from './Category';
import { Empty } from 'antd';
import * as utils from './../../resources/utils';

const Menu = ({ cart, addToCart }) => {
  // ------------------------------------------------------------------
  // ----------------------------- state ------------------------------
  // ------------------------------------------------------------------

  const [search, setSearch] = useState('');

  // ------------------------------------------------------------------
  // ----------------------------- logic ------------------------------
  // ------------------------------------------------------------------

  // filter the menu by search terms
  const filteredMenu = utils.filterMenuUsingSearch(search);

  // ------------------------------------------------------------------
  // ----------------------------- render -----------------------------
  // ------------------------------------------------------------------

  return (
    <div className="menu-container">
      <MenuBar
        menu={filteredMenu}
        search={search}
        setSearch={setSearch}
        cart={cart}
      />
      <div className="menu-items">
        {filteredMenu.length > 0 ? (
          filteredMenu.map(({ category, items }) => (
            <Category
              key={category}
              category={category}
              items={items}
              addToCart={addToCart}
            />
          ))
        ) : (
          <Empty description="No items found!" style={{ marginTop: '50px' }} />
        )}
      </div>
    </div>
  );
};

export default Menu;
