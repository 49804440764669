import React from 'react';
import { Result, Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import * as constants from './../../resources/constants';

const Confirmation = ({ form, updateCart }) => {
  // ------------------------------------------------------------------
  // ----------------------------- state ------------------------------
  // ------------------------------------------------------------------

  const history = useHistory();

  // ------------------------------------------------------------------
  // ---------------------------- handlers ----------------------------
  // ------------------------------------------------------------------

  const goToURL = (url) => {
    updateCart([]);
    const oldURL = history.location.pathname;
    if (oldURL !== url) {
      history.push(url);
      window.scrollTo(0, 0);
    }
  };

  // ------------------------------------------------------------------
  // ----------------------------- render -----------------------------
  // ------------------------------------------------------------------
  return (
    <Result
      icon={<CheckCircleFilled style={{ color: '#00cc99' }} />}
      status="success"
      title={'Thanks for ordering, ' + form.getFieldValue('first-name') + '!'}
      subTitle={
        'Your order will be ready for pickup at ' +
        form.getFieldValue('scheduled-time')
      }
      extra={[
        <Button
          key="home"
          type="primary"
          size="large"
          shape="round"
          onClick={() => goToURL(constants.urls.home)}
        >
          Go Home
        </Button>,
        <Button
          key="menu"
          size="large"
          shape="round"
          onClick={() => goToURL(constants.urls.menu)}
        >
          Buy Again
        </Button>,
      ]}
      style={{ flex: 1 }}
    />
  );
};

export default Confirmation;
