import React, { useState, useEffect } from 'react';
import { Steps } from 'antd';
import throttle from 'lodash/throttle';

const { Step } = Steps;

// variables for ease of access
const throttleTime = 200;
const mobileMatchMedia = '(max-width: 768px)';

const CheckoutSteps = ({ step, setCurrentStep }) => {
  // ------------------------------------------------------------------
  // ----------------------------- state ------------------------------
  // ------------------------------------------------------------------

  const [isMobile, setIsMobile] = useState(
    window.matchMedia(mobileMatchMedia).matches
  );

  // ------------------------------------------------------------------
  // --------------------------- life cycle ---------------------------
  // ------------------------------------------------------------------

  // conditional rendering for different size screens
  useEffect(() => {
    const resizeHandler = throttle(() => {
      const match = window.matchMedia(mobileMatchMedia).matches;
      if (isMobile !== match) setIsMobile(match);
    }, throttleTime);
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [isMobile]);

  // ------------------------------------------------------------------
  // ---------------------------- handlers ----------------------------
  // ------------------------------------------------------------------

  const clickHandler = (index) => {
    if (step > index) setCurrentStep(index);
  };

  // ------------------------------------------------------------------
  // ----------------------------- render -----------------------------
  // ------------------------------------------------------------------

  return (
    <div className="checkout-steps">
      <Steps
        current={step}
        responsive={false}
        size={isMobile ? 'small' : 'default'}
      >
        {['Review', 'Checkout', 'Finish'].map((title, index) => (
          <Step key={index} title={title} onClick={() => clickHandler(index)} />
        ))}
      </Steps>
    </div>
  );
};

export default CheckoutSteps;
