import React from 'react';
import { useHistory } from 'react-router-dom';
import * as constants from './../resources/constants';
import Navbar from './../components/Shared/Navbar';
import cartoonAVIF from './../styles/assets/images/takeout-box.avif';
import cartoonWEBP from './../styles/assets/images/takeout-box.webp';
import cartoonPNG from './../styles/assets/images/takeout-box.png';

const InfoPage = () => {
  // ------------------------------------------------------------------
  // ----------------------------- state ------------------------------
  // ------------------------------------------------------------------

  const history = useHistory();

  // ------------------------------------------------------------------
  // ---------------------------- handlers ----------------------------
  // ------------------------------------------------------------------

  const goToURL = (url) => {
    const oldURL = history.location.pathname;
    if (oldURL !== url) {
      history.push(url);
      window.scrollTo(0, 0);
    }
  };

  const convertTimeToAMPM = (timeStr) => {
    let [hrs, min] = timeStr.split(':');
    hrs = parseInt(hrs);
    const timeOfDay = hrs >= 12 ? 'PM' : 'AM';
    hrs %= 12;
    if (hrs === 0) hrs = 12;
    return `${hrs}:${min} ${timeOfDay}`;
  };

  // ------------------------------------------------------------------
  // ----------------------------- render -----------------------------
  // ------------------------------------------------------------------
  return (
    <>
      <Navbar />
      <div className="landing-page-wrapper">
        <div className="landing-banner-wrapper">
          <h1>
            New York Style
            <br />
            Chinese Food
          </h1>
          <div
            className="landing-order-button"
            onClick={() => goToURL(constants.urls.menu)}
          >
            Order Pickup
          </div>
          <div className="landing-banner-picture">
            <picture>
              <source type="image/avif" srcSet={cartoonAVIF} />
              <source type="image/webp" srcSet={cartoonWEBP} />
              <img
                className="takeout-cartoon-image"
                alt="chinese takeout box cartoon"
                src={cartoonPNG}
                width="405"
                height="485"
              />
            </picture>
          </div>
        </div>
        <div className="info-section-wrapper">
          <div className="info-section-block">
            <h3>Hours:</h3>
            {constants.hours.map((obj, index) => (
              <div
                className={
                  new Date().getDay() === index
                    ? 'hours-row row-active'
                    : 'hours-row'
                }
                key={index}
              >
                <div>{obj.day}</div>
                <div>
                  {convertTimeToAMPM(obj.open)} - {convertTimeToAMPM(obj.close)}
                </div>
              </div>
            ))}
          </div>
          <div className="info-section-block">
            <div className="info-section-block-section">
              <h3>Phone Number:</h3>
              <div>(386) 447-8788</div>
            </div>
            <div className="info-section-block-section">
              <h3>Address:</h3>
              <div>1475 Palm Coast Pkwy NW #106, Palm Coast, FL 32137</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoPage;
