import React, { useState, useEffect } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import Footer from './components/Shared/Footer';
import MenuPage from './views/MenuPage';
import CheckoutPage from './views/CheckoutPage';
import InfoPage from './views/InfoPage';
import './styles/css/App.scss';
import * as constants from './resources/constants';
import * as utils from './resources/utils';

const App = () => {
  // ------------------------------------------------------------------
  // ----------------------------- state ------------------------------
  // ------------------------------------------------------------------

  /*
    format: list of objects of the following form
    {
      name (string),
      price (number),
      amount (number),
      specialInstructions (string),
      ?description (string),
      ?spicy (boolean),
    }
  */
  const [cart, setCart] = useState([]);

  // ------------------------------------------------------------------
  // --------------------------- life cycle ---------------------------
  // ------------------------------------------------------------------

  useEffect(() => {
    const validatedCart = utils.getCartInLocalStorage();
    utils.setCartInLocalStorage(validatedCart);
    setCart(validatedCart);
  }, []);

  // ------------------------------------------------------------------
  // ---------------------------- handlers ----------------------------
  // ------------------------------------------------------------------

  /*
    add item to cart by either:
      - finding existing item with same description and updating count
      - adding item as new entry to cart
  */
  const addToCart = (item, amount, instructions) => {
    const newCart = cart.map((obj) => ({ ...obj }));
    let found = false;
    for (const obj of newCart) {
      if (obj.name === item.name && obj.specialInstructions === instructions) {
        obj.amount += amount;
        found = true;
        break;
      }
    }
    if (!found) {
      newCart.push({
        ...item,
        amount,
        specialInstructions: instructions,
      });
    }
    updateCart(newCart);
  };

  /*
    given an index into the cart, update count of the item
  */
  const updateItemCount = (index, count) => {
    const newCart = cart.map((obj) => ({ ...obj }));
    newCart[index].amount = count;
    updateCart(newCart);
  };

  /*
    update the cart in state and localStorage, filtering out all items with <= 0 amount
  */
  const updateCart = (newCart) => {
    newCart = newCart.filter((obj) => obj.amount > 0);
    utils.setCartInLocalStorage(newCart);
    setCart(newCart);
  };

  // ------------------------------------------------------------------
  // ----------------------------- render -----------------------------
  // ------------------------------------------------------------------

  return (
    <HashRouter basename="/">
      <div className="ultra">
        <Switch>
          <Route
            exact
            path={constants.urls.menu}
            component={() => <MenuPage cart={cart} addToCart={addToCart} />}
          />
          <Route
            path={constants.urls.checkout}
            component={() => (
              <CheckoutPage
                cart={cart}
                updateItemCount={updateItemCount}
                updateCart={updateCart}
              />
            )}
          />
          <Route path={constants.urls.info} component={() => <InfoPage />} />
          <Redirect to={constants.urls.home} />
        </Switch>
        <Footer />
      </div>
    </HashRouter>
  );
};

export default App;
