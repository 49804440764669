import React from 'react';
import { Form, Input, Radio, Select, message } from 'antd';
import Amplify, { API } from 'aws-amplify';
import awsconfig from './../../aws-exports';
import throttle from 'lodash/throttle';
import * as utils from './../../resources/utils';

Amplify.configure(awsconfig);
API.configure(awsconfig);
message.config({
  duration: 2,
  maxCount: 1,
});
const throttleTime = 3000;

const ContactInfo = ({ cart, form, setLoading, setCurrentStep }) => {
  // ------------------------------------------------------------------
  // ---------------------------- handlers ----------------------------
  // ------------------------------------------------------------------

  const onFinish = throttle((values) => {
    // check if scheduled time is still valid
    if (!utils.isSelectedTimeValid(values['scheduled-time'])) {
      message.error('Scheduled time is no longer valid!');
      return;
    }

    // set up post request
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    const body = {
      orders: cart,
      name: values['first-name'] + ' ' + values['last-name'],
      email: values['email'],
      phoneNumber: values['phone-number'],
      pickUpTime: values['scheduled-time'].padStart(8, '0'),
      date: utils.getDateString(),
    };

    // go into loading screen
    setLoading(true);

    // debugging locally
    // fetch('http://localhost:8080/order', {
    //   method: 'POST',
    //   mode: 'cors',
    //   headers,
    //   body: JSON.stringify(body),
    // })
    //   .then((data) => data.json())
    //   .then((data) => {
    //     setLoading(false);
    //     if (data.status === 200) {
    //       setCurrentStep(2);
    //       utils.setCartInLocalStorage([]);
    //     } else {
    //       message.error('Something went wrong! Please try again!');
    //     }
    //   })
    //   .catch(() => {
    //     setLoading(false);
    //     message.error('Something went wrong! Please try again!');
    //   });

    // send and handle request
    API.post('restaurantapi', '/order', { headers, body })
      .then((data) => {
        setLoading(false);
        if (data.status === 200) {
          setCurrentStep(2);
          utils.setCartInLocalStorage([]);
        } else {
          message.error('Something went wrong! Please try again!');
        }
      })
      .catch(() => {
        setLoading(false);
        message.error('Something went wrong! Please try again!');
      });
  }, throttleTime);

  // ------------------------------------------------------------------
  // ----------------------------- render -----------------------------
  // ------------------------------------------------------------------

  const validTimes = utils.getValidScheduleTimes();

  return (
    <div className="checkout-item-display">
      <h2 className="checkout-item-display-title">Contact Information</h2>
      <div className="checkout-contact-info">
        <Form
          colon={false}
          labelAlign="left"
          requiredMark={false}
          name="contact-information"
          form={form}
          onFinish={onFinish}
          initialValues={{ 'scheduled-time': validTimes[0] }}
        >
          <Form.Item
            name="first-name"
            rules={[
              {
                required: true,
                message: 'Please enter your first name.',
              },
            ]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            name="last-name"
            rules={[
              {
                required: true,
                message: 'Please enter your last name.',
              },
            ]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Please enter a valid email address.',
              },
            ]}
          >
            <Input placeholder="Email" type="email" />
          </Form.Item>
          <Form.Item
            name="phone-number"
            rules={[
              {
                pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                required: true,
                message: 'Please enter a valid phone number.',
              },
            ]}
          >
            <Input placeholder="Phone Number" type="tel" />
          </Form.Item>
          <Form.Item name="order-type" label={<b>Order Type:</b>}>
            <Radio checked>Pick Up</Radio>
          </Form.Item>
          <Form.Item name="payment-type" label={<b>Payment Type:</b>}>
            <Radio checked>Pay At Counter</Radio>
          </Form.Item>
          <Form.Item
            name="scheduled-time"
            label={<b>Schedule For:</b>}
            style={{ flex: '1' }}
            rules={[
              {
                required: true,
                message: 'Please select a pick up time.',
              },
            ]}
          >
            <Select>
              {validTimes.map((time) => (
                <Select.Option key={time} value={time}>
                  {time}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ContactInfo;
