import React from 'react';
import * as utils from './../../resources/utils';

const Footer = () => (
  <div className="footer">
    <a className="copyright" href="#top">
      © {utils.getCurrentYear()} China King. All Rights Reserved.
    </a>
  </div>
);

export default Footer;
