import React, { useState } from 'react';
import spicy from './../../styles/assets/images/spicy.png';
import { InputNumber } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const CartItem = ({ index, item, updateItemCount }) => {
  // ------------------------------------------------------------------
  // ----------------------------- state ------------------------------
  // ------------------------------------------------------------------

  const [count, setCount] = useState(item.amount);

  // ------------------------------------------------------------------
  // ---------------------------- handlers ----------------------------
  // ------------------------------------------------------------------

  const stepHandler = (val) => {
    setCount(val);
    updateItemCount(index, val);
  };

  const validateAmount = () => {
    if (!count || !/^\d+$/.test(count)) {
      setCount(item.amount);
      updateItemCount(index, item.amount);
    } else {
      updateItemCount(index, count);
    }
  };

  const removeItem = () => {
    updateItemCount(index, 0);
  };

  // ------------------------------------------------------------------
  // ----------------------------- render -----------------------------
  // ------------------------------------------------------------------

  return (
    <div className="checkout-item-card">
      <div className="card-top-container">
        <div className="card-top-row">
          <div className="card-name-spicy-container">
            <h5>{item.name}</h5>
            {item.spicy && (
              <img alt="spicy" src={spicy} width="15" height="15" />
            )}
          </div>
          <div className="checkout-delete-item" onClick={removeItem}>
            <CloseOutlined />
          </div>
        </div>
        {item.specialInstructions && (
          <div className="checkout-item-instructions">
            <span>Instructions: </span>
            {item.specialInstructions}
          </div>
        )}
      </div>
      <div className="checkout-item-card-bottom">
        <div className="checkout-quantity">
          <span>Qty: </span>
          <InputNumber
            min={1}
            size="small"
            style={{ width: '50px' }}
            keyboard={false}
            inputMode="numeric"
            value={count}
            onChange={setCount}
            onStep={stepHandler}
            onPressEnter={validateAmount}
            onBlur={validateAmount}
          />
        </div>
        <span>${(item.amount * item.price).toFixed(2)}</span>
      </div>
    </div>
  );
};

export default CartItem;
