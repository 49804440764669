import React from 'react';
import { useHistory } from 'react-router-dom';
import * as constants from './../../resources/constants';

const Navbar = () => {
  // ------------------------------------------------------------------
  // ----------------------------- state ------------------------------
  // ------------------------------------------------------------------

  const history = useHistory();

  // ------------------------------------------------------------------
  // ---------------------------- handlers ----------------------------
  // ------------------------------------------------------------------

  const goToURL = (url) => {
    const oldURL = history.location.pathname;
    if (oldURL !== url) {
      history.push(url);
      window.scrollTo(0, 0);
    }
  };

  // ------------------------------------------------------------------
  // ----------------------------- render -----------------------------
  // ------------------------------------------------------------------
  return (
    <div className="navbar">
      <div className="navbar-items">
        <div className="navbar-about">
          <h3 onClick={() => goToURL(constants.urls.home)}>China King</h3>
        </div>
        <div className="navbar-space" />
        <div className="navbar-links">
          <div onClick={() => goToURL(constants.urls.info)}>Info</div>
          <div onClick={() => goToURL(constants.urls.menu)}>Menu</div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
