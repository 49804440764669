import React, { useState } from 'react';
import { Modal, Input, message } from 'antd';
import { CloseOutlined, CheckCircleFilled } from '@ant-design/icons';
import spicy from './../../styles/assets/images/spicy.png';
import * as utils from './../../resources/utils';

const { TextArea } = Input;

message.config({
  duration: 2,
  maxCount: 1,
});

const MenuModal = ({ item, open, setOpen, addToCart, category }) => {
  // ------------------------------------------------------------------
  // ----------------------------- state ------------------------------
  // ------------------------------------------------------------------

  const [value, setValue] = useState('');
  const [count, setCount] = useState(1);

  // ------------------------------------------------------------------
  // ---------------------------- handlers ----------------------------
  // ------------------------------------------------------------------

  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    if (count > 1) setCount(count - 1);
  };

  const closeModal = () => {
    setCount(1);
    setValue('');
    setOpen(false);
  };

  const addToCartHandler = () => {
    const isLunchSpecial = category === 'Lunch Specials';
    const isValidTime = utils.isValidTimeForLunchSpecial();
    const disabled = isLunchSpecial && !isValidTime;
    if (disabled) {
      message.error('Cannot order outside of lunch hours!');
    } else {
      message.success({
        content: 'Item added to cart!',
        icon: <CheckCircleFilled style={{ color: 'rgb(0, 204, 153)' }} />,
      });
      addToCart(item, count, value.trim());
    }
  };

  // ------------------------------------------------------------------
  // ----------------------------- render -----------------------------
  // ------------------------------------------------------------------

  return (
    <Modal
      visible={open}
      centered
      onOk={closeModal}
      onCancel={closeModal}
      footer={null}
      closable={false}
    >
      <div className="modal-header">
        <div className="modal-close-container">
          <CloseOutlined onClick={closeModal} />
        </div>
      </div>
      <div className="modal-item-name">
        <h2>{item.name}</h2>
        {item.spicy && <img alt="spicy" src={spicy} width="24" height="24" />}
      </div>
      {item.description && (
        <div className="modal-item-description">{item.description}</div>
      )}
      <div className="modal-special-instructions-container">
        <span className="modal-special-instructions-header">
          Special Instructions
        </span>
        <div className="modal-textarea-wrapper">
          <TextArea
            bordered={false}
            placeholder={'Add instructions...'}
            showCount
            maxLength={200}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="modal-item-count-wrapper">
          <div
            className={
              count > 1 ? 'modal-change-count' : 'modal-change-count disabled'
            }
            onClick={decrementCount}
          >
            -
          </div>
          <div className="modal-item-count">{count}</div>
          <div className="modal-change-count" onClick={incrementCount}>
            +
          </div>
        </div>
        <div className="modal-add-to-cart" onClick={addToCartHandler}>
          <div className="modal-add-to-cart-space"></div>
          <div className="modal-add-to-cart-text">Add to Cart</div>
          <div className="modal-add-to-cart-price">
            ${(item.price * count).toFixed(2)}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MenuModal;
